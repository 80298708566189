import React, {useState} from 'react';
import {COLORS} from '../../../assets/color';
import {MdDelete} from 'react-icons/md';
import {BiEdit} from 'react-icons/bi';
import {IProduct} from '../../../components/interfaces/products';
import ConfirmDelete from '../../../components/modal/ConfirmDelete';
import {useNavigate} from 'react-router-dom';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {useProduct, useVendor} from '../../../app/hooks';
import {toast} from 'react-toastify';
import {axiosError, numberFormat} from '../../../components/Helpers';
import {setSingleProduct} from '../../../redux/slices/product/productSlice';
import {useDispatch} from 'react-redux';

interface IVendorProductTable {
  products: IProduct[];
  setProducts: React.Dispatch<any>;
}
const VendorProductTable = ({products, setProducts}: IVendorProductTable) => {
  // const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();
  const token = useVendor().token;
  const productId = useProduct().product;

  // Delete Product
  const onDelete = async () => {
    try {
      const response = await VendorServices.deleteProduct(token, productId);
      if (response.data.status === 'success') {
        const updatedProducts = products?.filter(
          (p: any) => p.id !== productId
        );
        setProducts(updatedProducts);

        // console.log(response.data);
        toast.success(response.data.data.message);
      }
    } catch (error: any) {
      axiosError(error.response);
      console.log(error);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <div className=' sm:block hidden'>
        <table className=' mt-10 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10 rounded-tl-md'>Image</th>
              <th className='w-10'>Name</th>
              <th className='w-10'>stock</th>
              <th className='w-10'>Price</th>
              <th className='w-10'>Status</th>
              <th className='w-16 rounded-tr-md'>Actions</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#F8FAFC]'>
            {products?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={6}>
                  <span className='text-base text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {products?.map((product: any) => {
                return (
                  <tr className='overflow-x-auto font-medium' key={product.id}>
                    <td className=''>
                      <div className='flex justify-center'>
                        <div className='w-20 h-20 self-center'>
                          <img
                            src={
                              product.additionalImg?.[0] || product?.productImg
                            }
                            alt=''
                            className='object-contain w-full rounded-xl h-full'
                          />
                        </div>
                      </div>
                    </td>
                    <td className='w-10 text-center'>
                      {product.name.slice(0, 40) + '...'}
                    </td>
                    <td className='w-10 text-center'>{product.quantity}</td>
                    <td className='w-10 text-center'>
                      ₦{numberFormat(product.price)}
                    </td>
                    <td className='w-10 text-center capitalize'>
                      <div
                        className={
                          product.status === 'awaiting-approval'
                            ? ' bg-[#FDF3E4] px-3 py-2 text-[#FFAD33] rounded-md shadow-sm'
                            : product.status === 'approved'
                            ? 'bg-[#E6FCE5] px-3 py-2 text-[#35C655] rounded-md shadow-sm'
                            : 'bg-[#FEEFEE] px-3 py-2 text-[#C81E19] rounded-md shadow-sm'
                        }>
                        {' '}
                        {product.status}
                      </div>
                    </td>
                    <td className='w-16'>
                      <div className='flex flex-row justify-evenly items-center'>
                        {/* Edit */}
                        <span
                          onClick={() => {
                            navigate(`${product.id}`);
                          }}>
                          <BiEdit
                            color={COLORS.neutral}
                            size={24}
                            style={{cursor: 'pointer'}}
                            title='Edit Product'
                          />
                        </span>
                        {/* Delete */}
                        <span
                          onClick={() => {
                            setShowDelete(true);
                            console.log(product.id);
                            dispatch(setSingleProduct(product.id));
                          }}>
                          <MdDelete
                            color={COLORS.error}
                            size={24}
                            style={{cursor: 'pointer'}}
                            title='Delete Product'
                          />
                        </span>
                        {/* Confirm Delete Modal */}
                        {showDelete && (
                          <ConfirmDelete
                            setIsOpen={setShowDelete}
                            deleHandler={onDelete}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>

      {/* Table Mobile */}
      <div className='sm:hidden flex items-center justify-center w-full'>
        <table className='border-separate border-spacing-y-2 text-sm overflow-x-auto w-full table-fixed'>
          <thead className=''>
            <tr className='bg-orange-100 h-[3.125rem] text-primary'>
              <th className='rounded-tl-md'>Name</th>
              <th className=''>Price</th>
              <th className=''>Status</th>
              <th className='rounded-tr-md'>Actions</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-50'>
            {products?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={4}>
                  <span className='text-base text-primary font-semibold'>
                    No Product available
                  </span>
                </td>
              </tr>
            )}
            <>
              {products?.map((product: any) => {
                return (
                  <tr key={product.id}>
                    <td className=' text-center td-class'>{product.name}</td>
                    <td className=' text-center td-class'>{product.price}</td>
                    <td className=' text-center td-class capitalize'>
                      <span
                        className={
                          product.status === 'awaiting-approval'
                            ? ' bg-[#FDF3E4] px-2 py-2 text-[#FFAD33] rounded-md shadow-sm '
                            : product.status === 'approved'
                            ? 'bg-[#E6FCE5] px-2 py-2 text-[#35C655] rounded-md shadow-sm'
                            : 'bg-[#FEEFEE] px-2 py-2 text-[#C81E19] rounded-md shadow-sm'
                        }>
                        {product.status === 'awaiting-approval'
                          ? 'Awaiting'
                          : product.status}
                      </span>
                    </td>
                    <td className='td-class'>
                      <div className='flex flex-row justify-evenly items-center gap-2'>
                        {/* Edit */}
                        <span
                          onClick={() => {
                            navigate(`${product.id}`);
                          }}>
                          <BiEdit
                            color={COLORS.neutral}
                            size={24}
                            style={{cursor: 'pointer'}}
                            title='Edit Product'
                          />
                        </span>
                        {/* Delete */}
                        <span
                          onClick={() => {
                            setShowDelete(true);
                            dispatch(setSingleProduct(product.id));
                          }}>
                          <MdDelete
                            color={COLORS.error}
                            size={24}
                            style={{cursor: 'pointer'}}
                            title='Delete Product'
                          />
                        </span>
                        {/* Confirm Delete Modal */}
                        {showDelete && (
                          <ConfirmDelete
                            setIsOpen={setShowDelete}
                            deleHandler={onDelete}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VendorProductTable;
