import React from 'react';
import {IOrder} from '../../../components/interfaces/products';
import moment from 'moment';
import {numberFormat} from '../../../components/Helpers';

interface IVendorOrderTable {
  orders: IOrder[];
}
const TransactionTable = ({orders}: IVendorOrderTable) => {
  return (
    <>
      <div className=' sm:block hidden'>
        <table className=' mt-4 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10 rounded-tl-md'>Product ID</th>
              <th className='w-16'>Transaction Date</th>
              <th className='w-10'>Product Name</th>
              <th className='w-10'>Quantity</th>
              <th className='w-10'>Amount</th>
              <th className='w-10'>Returned</th>
              <th className='w-10'>Settled</th>
              {/* <th className='w-10'>Payment Method</th> */}
              {/* <th className='w-16 rounded-tr-md'>Actions</th> */}
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#F8FAFC]'>
            {orders?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={7}>
                  <span className='text-base text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {orders?.map((order: any) => {
                return (
                  <tr
                    className='overflow-x-auto h-[3rem] font-medium'
                    key={order.id}>
                    <td className='w-10 text-center'>
                      #{order?.productId.slice(0, 6).toUpperCase()}
                    </td>
                    <td className='w-16 text-center'>
                      {moment(order?.createdAt).format('ll')}
                    </td>
                    <td className='w-10 text-center'>{order?.product.name}</td>
                    <td className='w-10 text-center'>{order?.quantity}</td>
                    <td className='w-10 text-center'>
                      ₦{numberFormat(order?.subTotal)}
                    </td>
                    <td className='w-10 text-center'>
                      {order?.isReturned ? 'Yes' : 'No'}
                    </td>
                    <td className='w-10 text-center'>
                      {order?.isMovedToSettlement ? 'Yes' : 'No'}
                    </td>
                    {/* <td className='w-10 text-center'>{order.paymentMethod}</td> */}
                    {/* <td className='w-16'></td> */}
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>

      {/* Table Mobile */}
      <div className='sm:hidden flex items-center justify-center w-full'>
        <table className='border-separate border-spacing-y-2 text-sm overflow-x-auto w-full table-fixed'>
          <thead className=''>
            <tr className='bg-orange-100 h-[3.125rem] text-primary'>
              <th className=' rounded-tl-md'>Name</th>
              <th className=''>Date</th>
              <th className=''>Qty</th>
              <th className=''>Price</th>
              <th className=''>Return</th>
              <th className=''>Settle</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-50'>
            {orders?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={6}>
                  <span className='text-base text-primary font-semibold'>
                    No Product available
                  </span>
                </td>
              </tr>
            )}
            <>
              {orders?.map((order: any) => {
                return (
                  <tr key={order.id}>
                    <td className=' text-center td-transClass'>
                      {order?.product.name}
                    </td>
                    <td className=' text-center td-transClass'>
                      {moment(order?.createdAt).format('ll')}
                    </td>
                    <td className=' text-center td-transClass'>
                      {order?.quantity}
                    </td>
                    <td className=' text-center td-transClass'>
                      ₦{numberFormat(order?.subTotal)}
                    </td>
                    <td className='text-center td-transClass'>
                      {order?.isReturned ? 'Yes' : 'No'}
                    </td>
                    <td className='text-center td-transClass'>
                      {order?.isMovedToSettlement ? 'Yes' : 'No'}
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TransactionTable;
