/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect } from "react";
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {adminCard} from '../../../assets/images';

import Header from '../../../components/layouts/header/Header';
import {useAdmin, useProduct} from '../../../app/hooks';
import {useEffect, useState} from 'react';
import OverviewCard, {
  IOverviewCardProps,
} from '../../../components/dashboard/OverviewCard';
import {BsFillBoxFill} from 'react-icons/bs';
import {Icon} from '@iconify/react';
import {Chart} from 'react-google-charts';
import {IVendor} from '../../../components/interfaces/vendors';
import {AdminServices} from '../../../redux/services/admin/admin';
import VendorsList from '../../../components/dashboard/VendorList';
import ProductListTabs from '../../../components/dashboard/ProductListTabs';
import {
  axiosError,
  calculateTotalForCurrentMonth,
} from '../../../components/Helpers';
import Spinner from '../../../components/spinner/Spinner';

interface ChartData {
  productName: string;
  quantity: number;
}

const Home = () => {
  const user = useAdmin().admin;
  const product = useProduct()?.products;
  const approvedProducts = product.filter(
    (approved) => approved.status === 'approved'
  );
  const pendingProducts = product.filter(
    (pending) => pending.status === 'awaiting-approval'
  );
  const outOfStockProducts = product.filter(
    (product: any) => product.quantity === 0
  );

  const [settlements, setSettlements] = useState<any>([]);
  const [isSettleLoading, setIsSettleLoading] = useState(false);

  // Get Settlements
  useEffect(() => {
    getSettlements();
    getVendors();
  }, []);

  const getSettlements = async () => {
    setIsSettleLoading(true);
    try {
      const response = await AdminServices.getSettlements(token);
      if (response.data.status === 'success') {
        const res = response?.data.data.details;
        setSettlements(res);
        setIsSettleLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  const TotalSalesByMonth = calculateTotalForCurrentMonth(settlements);
  // console.log(TotalSalesByMonth, 'sss');
  const OverviewData: IOverviewCardProps[] = [
    {
      icon: (
        <div className='w-[24px] h-[24px]'>
          <img
            src={adminCard}
            alt='card'
            className='w-full h-full object-contain'
          />
        </div>
      ),
      title: 'Total Monthly Sale',
      amount: Number(TotalSalesByMonth),
    },
    {
      icon: <BsFillBoxFill size={22} />,
      title: 'Total Number of Products',
      value: product.length,
    },
    {
      icon: <BsFillBoxFill size={22} />,
      title: 'Total Approved Product',
      value: approvedProducts.length,
    },
    {
      icon: <BsFillBoxFill size={22} />,
      title: 'Total Pending Product',
      value: pendingProducts.length,
    },
    {
      icon: <Icon icon='mdi:receipt-text-pending' fontSize={22} />,
      title: 'Total Pending Orders',
      value: 20,
    },
    {
      icon: <Icon icon='lucide:package-x' fontSize={22} />,
      title: 'Total Out of Stock Products',
      value: outOfStockProducts.length,
    },
  ];

  // Google Pie Chart
  const pieData = [
    ['Users', 'Number of Users'],
    ['Buyers', 6],
    ['Vendors', 4],
  ];

  const pieOptions = {
    is3D: true,
    height: 320,
    width: 500,
    backgroundColor: 'transparent',
    legend: {
      position: 'right', // Position the legend at the right
      alignment: 'end', // Align the legend to the end (right side)
    },
    legendShape: 'square', // Set legend shape to square
    colors: ['#7280FE', '#C5CBFC'],
  };

  // Get Vendors
  // Get Vendors
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState<IVendor[]>([]);
  const token = useAdmin().adminToken;

  const getVendors = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.allVendors(token);
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response.data?.data.vendors;
        setVendors(res.rows);
        // console.log(res.rows);
        setIsLoading(false);
        // console.log('profile', profile);
      }
    } catch (error: any) {
      // axiosError(error.response);
      setIsLoading(false);
    }
  };

  const productMap: {[productName: string]: number} = {};
  settlements.forEach((product: any) => {
    const quantity = parseInt(product.quantity);
    if (!isNaN(quantity)) {
      if (!productMap[product.productName]) {
        productMap[product.productName] = quantity;
      } else {
        productMap[product.productName] += quantity;
      }
    }
  });

  // Convert to array and sort by aggregated quantity in descending order
  const aggregatedProducts: ChartData[] = Object.keys(productMap).map(
    (productName) => ({
      productName,
      quantity: productMap[productName],
    })
  );
  // console.log(aggregatedProducts);

  // Take the top 6 products with highest aggregated quantities
  const topProducts: ChartData[] = aggregatedProducts
    .sort((a, b) => b.quantity - a.quantity)
    .slice(0, 6);

  // console.log(topProducts);
  // Prepare data in the format of barData with distinct colors
  const allColors = [
    '#2E3ECE',
    '#CFEBFC',
    '#141470',
    '#4B4BCB',
    '#080821',
    '#D3D3F9',
  ];
  const barData = [
    [
      'Element',
      `Products:`,
      {role: 'style'},
      {sourceColumn: 50, role: 'annotation', type: 'string', calc: 'stringify'},
    ],
    ...topProducts.map((product, index) => [
      product.productName,
      product.quantity,
      allColors[index], // Assigning distinct color index based on the top products index
      null, // Placeholder for annotation, can be customized if needed
    ]),
  ];

  // Updated chart options with customized styling and colors
  const barOptions = {
    colors: ['#fcb441', 'blue'], // Assign different colors
    chartArea: {
      width: '85%',
      height: '80%',
    },
    backgroundColor: 'transparent',
    bar: {groupWidth: '80%'},
    legend: {position: 'none'},
    hAxis: {
      baselineColor: 'none',
      textPosition: 'center',
      slantedText: true,
    },
    vAxis: {
      gridlines: {
        count: 0,
      },
    },
    animation: {
      startup: true,
      easing: 'linear',
      duration: 1500,
    },
    // enableInteractivity: false,
  };

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        {isSettleLoading && <Spinner />}
        <Helmet>
          <meta charSet='utf-8' />
          <title>Dashboard</title>
        </Helmet>

        <div className='pb-4'>
          <Header />
        </div>
        <div>
          <p className='text-lg text-[#878987] font-poppinsRegular inline-flex'>
            Welcome,
            <span className='text-black font-poppinsMedium pl-2'>
              {user?.firstName} 👋
            </span>
          </p>
        </div>
        {/* Statistics part */}
        <div className='flex xl:flex-row xl:justify-between flex-col justify-center items-start gap-6 py-4 w-full'>
          <div className='xl:w-[60%] w-full flex flex-wrap items-center lg:justify-start justify-center gap-4 '>
            <OverviewCard datasets={OverviewData} type='Admin' />
          </div>

          {/* Pie Chat  Total Number of Visitors */}
          <div className='xl:w-[40%]  w-full flex flex-col justify-center items-center bg-white rounded-lg p-6 shadow-md'>
            <p className='text-xl font-poppinsSemibold text-black'>
              Total Number of Visitors
            </p>

            {/* Chart */}
            <div className='h-full'>
              <Chart
                chartType='PieChart'
                data={pieData}
                options={pieOptions}
                legendToggle
                loader={
                  <div className='font-poppinsRegular text-sm'>
                    Loading Chart
                  </div>
                }
                // height={'400px'}
              />
            </div>
          </div>
        </div>
        {/* Products */}
        <div className='flex xl:flex-row xl:justify-between flex-col justify-center items-start gap-12 py-4 w-full'>
          <div className='xl:w-[57%] w-full bg-white rounded-lg p-2 shadow-md h-[700px]'>
            <ProductListTabs />
          </div>

          {/* Pie Chat  Total Number of Visitors */}
          <div className='xl:w-[40%]  w-full'>
            {/* Bar Chart */}
            <div className='bg-white rounded-lg p-6 shadow-md'>
              <p className='text-xl font-poppinsSemibold text-black'>
                Most purchased product
              </p>

              {/* Chart */}
              <div className='h-full '>
                {barData.length === 1 ? (
                  <p className='text-sm font-poppinsRegular text-black'>
                    No purchased product yet
                  </p>
                ) : (
                  <Chart
                    chartType='ColumnChart'
                    width='100%'
                    height='400px'
                    data={barData}
                    options={barOptions}
                    loader={
                      <div className='font-poppinsRegular text-sm'>
                        Loading Chart
                      </div>
                    }
                  />
                )}
              </div>
            </div>
            {/* vendors */}
            <div className='bg-white rounded-lg p-6 shadow-md mt-4'>
              <p className='text-xl font-poppinsSemibold text-black'>Vendors</p>

              {/* Vendors */}
              <>
                {isLoading ? (
                  <div className='h-4 w-full animate-pulse'></div>
                ) : (
                  <VendorsList data={vendors} />
                )}
                {vendors.length === 0 && <div>No Vendors Available</div>}
              </>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  margin-bottom: 3rem;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
  height: 100%;
`;

export default Home;

// border-l border-l-primary border-b border-b-primary
